const {
	v4: uuidV4,
	parse: uuidParse,
	stringify: uuidStringify
} = require("uuid");

const promisify = (method) => {
	return (...args) => {
		try {
			const result = method(...args);

			if(result instanceof Promise) {
				return result;
			}

			return Promise.resolve(result);
		} catch(error) {
			return Promise.reject(error);
		}
	};
};

const msgpack = require("msgpack-lite");

module.exports = (handlerTable, writeMethod) => {
	const protocol = {
		_taskLookup: {},

		_handleCall: (data) => {
			const id = uuidStringify(data.id);

			const handler = handlerTable[data.command + ""];

			if(handler === undefined) {
				return protocol.response(id, false, new Error("Command not registered"));
			}

			return promisify(handler)(data.data).then(data => {
				return protocol.response(id, true, data);
			}).catch(error => {
				return protocol.response(id, false, error);
			});
		},

		_handleResponse: (data) => {
			const id = uuidStringify(data.id);

			const task = protocol._taskLookup[id];

			if(task === undefined) {
				return;
			}

			if(data.success) {
				task.resolve(data.data);
			} else {
				task.reject(data.data);
			}
		},

		readMethod: (arrayBuffer) => {
			const data = new Uint8Array(arrayBuffer);

			const decodedMessage = msgpack.decode(data);

			const typeHandlers = {
				call: protocol._handleCall,
				response: protocol._handleResponse
			};

			const handler = typeHandlers[decodedMessage.type + ""];

			if(handler) {
				handler(decodedMessage);
			}
		},

		response: (uuid, success, data) => {
			const binaryUuid = uuidParse(uuid);

			const packet = {
				type: "response",
				id: binaryUuid,
				start: new Date(),
				data,
				success
			};

			const encodedPacket = msgpack.encode(packet);

			writeMethod(encodedPacket);
		},

		call: (name, data) => {
			const uuid = uuidV4();

			const binaryUuid = uuidParse(uuid);

			const packet = {
				type: "call",
				id: binaryUuid,
				start: new Date(),
				command: name,
				data
			};

			const encodedPacket = msgpack.encode(packet);

			return new Promise((resolve, reject) => {
				protocol._taskLookup[uuid] = {
					resolve, reject
				};

				writeMethod(encodedPacket);
			});
		}
	};

	return protocol;
};
