const EventEmitter = require("events");
const OpusMediaRecorder = require("opus-media-recorder");

const log = require("./log");

module.exports = () => {
	const recorder = {
		_events: new EventEmitter(),
		_mediaRecorder: undefined,
		_stream: undefined,

		getMediaRecorder: (stream) => {
			if(window.MediaRecorder.isTypeSupported("audio/ogg; codecs=opus")) {
				return new window.MediaRecorder(stream, { mimeType: "audio/ogg; codecs=opus" });
			}

			log.warning("For best experience, please, use firefox");

			const workerOptions = {
				encoderWorkerFactory: function () {
					return new Worker(new URL("../node_modules/opus-media-recorder/encoderWorker.umd.js", import.meta.url))
				},
				OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
				WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
			};

			return new OpusMediaRecorder(stream, { mimeType: "audio/ogg; codecs=opus" }, workerOptions);
		},

		getLocalStream: () => {
			return navigator.mediaDevices
				.getUserMedia({ video: false, audio: true })
				.then((stream) => {
					const track = stream.getAudioTracks()[0];

					return track.applyConstraints({
						audio: {
							channelCount: 1,
							sampleRate: 24000,
						}
					}).then(() => stream);
				})
				.catch((err) => {
					console.error(`you got an error: ${err}`);
					alert(error);
				});
		},

		record: () => {
			return recorder.getLocalStream().then(stream => {
				const mediaRecorder = recorder.getMediaRecorder(stream);

				mediaRecorder.start(10);

				mediaRecorder.ondataavailable = (e) => {
					if(e.data.length === 0) {
						return;
					}

					recorder._events.emit("samples", e.data);
				};

				recorder._mediaRecorder = mediaRecorder;
				recorder._stream = stream;
			});
		},

		stop: () => {
			if(recorder._mediaRecorder) {
				recorder._mediaRecorder.stop();

				recorder._stream.getTracks().forEach(function(track) {
					track.stop();
				});
			}
		},

		on: (...args) => {
			return recorder._events.on(...args);
		}
	};

	return recorder;
};
