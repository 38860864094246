const OggOpusDecoder = require("ogg-opus-decoder").OggOpusDecoderWebWorker;
const OpusDecoder = require("opus-decoder").OpusDecoderWebWorker;

const codecMap = {
	opus: OpusDecoder,
	"ogg/opus": OggOpusDecoder
};

module.exports = (codec) => {
	if(codecMap[codec] === undefined) {
		return;
	}

	const decoder = new codecMap[codec]({
		sampleRate: 24000,
		forceStereo: false
	});

	console.log(decoder);

	const audioCtx = window.audioCtx;

	const player = {
		samples: new Float32Array(0),

		_playing: false,

		_latency: 200,

		_playRealtime: () => {
			if(player._playing) {
				return;
			}

			player._playing = true;

			const bufferSize = 256;

			const scriptNode = audioCtx.createScriptProcessor(bufferSize, 0, 1);

			scriptNode.addEventListener("audioprocess", (audioProcessingEvent) => {
				if(player._playing === false) {
					return;
				}

				const outputBuffer = audioProcessingEvent.outputBuffer;
				const outputData = outputBuffer.getChannelData(0);

				if(player.samples.length === 0) {
					scriptNode.disconnect(audioCtx.destination);
					player._playing = false;
				}

				let copied = 0;

				player.samples.slice(0, 256).forEach((val, key) => {
					copied++;
					outputData[key] = val;
				});

				player.samples = player.samples.slice(256);
			});

			scriptNode.connect(audioCtx.destination);
		},

		_playBuffer: (buffer) => {
			const myArrayBuffer = audioCtx.createBuffer(
				1,
				buffer.length,
				24000,
			);

			const chan = myArrayBuffer.getChannelData(0);

			buffer.forEach((sample, key) => {
				chan[key] = sample;
			});

			const source = audioCtx.createBufferSource();

			source.buffer = myArrayBuffer;

			source.connect(audioCtx.destination);

			source.start();
		},

		_concat: (buffer1, buffer2) => {
			const tmp = new Float32Array(buffer1.length + buffer2.length);

			tmp.set(new Float32Array(buffer1), 0);
			tmp.set(new Float32Array(buffer2), buffer1.length);

			return tmp;
		},

		_decode: (buffer) => {
			if(codec === "opus") {
				return decoder.decodeFrame(new Uint8Array(buffer));
			} else if (codec === "ogg/opus") {
				return decoder.decode(new Uint8Array(buffer));
			} else {
				throw new Error("Unknown codec " + codec);
			}
		},

		decode: (data) => {
			decoder.ready.then(() => {
				if(!player._playing) {
					setTimeout(() => {
						player._playRealtime();
					}, 4 * player._latency);
				}

				player._decode(data).then(data => {
					console.log(data);

					if(data.channelData[0] === undefined) {
						return;
					}

					player.samples = player._concat(player.samples, data.channelData[0]);

					if(!player._playing) {
						setTimeout(() => {
							player._playRealtime();
						}, 4 * player._latency);
					}
				});
			});
		},

		setLatency: latency => (player._latency = latency),

		reset: () => {
			return decoder.reset();
		}
	};

	return player;
};
