const logs = document.querySelector(".logs");

module.exports = {
	log: message => {
		console.log(message);

		const log = document.createElement("div");

		log.classList.add("log");

		log.innerText = message;

		logs.prepend(log);
	},

	error: message => {
		console.error(message);

		const log = document.createElement("div");

		log.classList.add("log");
		log.classList.add("error");

		log.innerText = message;

		logs.prepend(log);
	},

	warning: message => {
		console.error(message);

		const log = document.createElement("div");

		log.classList.add("log");
		log.classList.add("warning");

		log.innerText = message;

		logs.prepend(log);
	}
};
