const binaryProtocol = require("@nemit/binary-protocol");

const recorder = require("./recorder");
const player = require("./player");
const log = require("./log");

log.log("Welcome to nemit, press connect to start. ");

const ping = (protocol) => {
	const start = Date.now();

	return protocol.call("echo", "echo").then(() => {
		const stop = Date.now();

		return stop - start;
	});
};

const estimateLatency = (protocol) => {
	const pings = Array(10).fill().reduce((prev) => {
		if(prev.length === 0) {
			prev.push(ping(protocol));

			return prev;
		}

		prev.push(prev.slice(-1)[0].then(() => ping(protocol)));

		return prev;
	}, []);

	return Promise.all(pings).then(pings => {
		return pings.reduce((prev, cur) => prev + cur * (1 / pings.length), 0);
	});
};

const openSocket = () => {
	return new Promise((resolve, reject) => {
		const protocol = "ws" + window.location.protocol.slice(4, -1);

		let url = `${protocol}://${window.location.host}/nemit`;

		if(window.location.hostname === "localhost") {
			url = "wss://nemit.pro/nemit";
		}

		const webSocket = new WebSocket(url);
		webSocket.binaryType = "arraybuffer";

		webSocket.addEventListener("open", () => {
			resolve(webSocket);
		});

		// Handle error opening
	});
};

const openNemit = () => {
	return openSocket().then(socket => {
		const playerInstances = {
			"ogg/opus": player("ogg/opus"),
			"opus": player("opus")
		};

		let lastBroadcastID = undefined;

		const protocol = binaryProtocol({
			broadcast: (data) => {
				if(lastBroadcastID && lastBroadcastID != data.id) {
					playerInstances[data.codec].reset();
				}

				playerInstances[data.codec].decode(data.samples);

				lastBroadcastID = data.id;
			},

			getLock: () => {
				button.classList.add("ptt-disabled");
			},

			releaseLock: () => {
				button.classList.remove("ptt-disabled");
			}
		}, (...args) => socket.send(...args));

		log.log("Estimating latency");

		estimateLatency(protocol).then(realLatency => {
			const latency = Math.round(realLatency);

			Object.values(playerInstances).forEach(el => el.setLatency(latency));

			log.log(`Latency is around ${latency}ms. `);
		});

		socket.onmessage = (message) => protocol.readMethod(message.data);

		return protocol;
	});
};

const button = document.querySelector("#ptt");

const connect = () => {
	button.removeEventListener("click", connect);

	button.innerText = "PTT";

	const ctxOption = {
		sampleRate: 24000
	};

	window.audioCtx = new (window.AudioContext || window.webkitAudioContext)(ctxOption)

	log.log(`Sample rate will be: ${window.audioCtx.sampleRate}`);

	openNemit().then(protocol => {
		let pttPressed = false;
		let recorderInstance = false;

		protocol.call("echo", "connected").then(response => {
			console.log(response);

			button.classList.remove("ptt-disabled");
		});

		button.addEventListener("click", () => {
			if(!pttPressed) {
				protocol.call("getLock").then(lock => {
					console.log(lock);

					if(!lock) {
						log.error("Someone already talking");

						return;
					}

					pttPressed = !pttPressed;

					log.log("You are transmitting");

					recorderInstance = recorder();

					const start = Date.now();
					let last = start;

					recorderInstance.on("samples", samples => {

						samples.arrayBuffer().then(buffer => {
							console.log(Date.now() - start, Date.now() - last, buffer.byteLength);
							last = Date.now();

							if(buffer.byteLength === 0) {
								return;
							}

							protocol.call("broadcast", {
								codec: "ogg/opus",
								samples: buffer,
								id: lock
							});
						});
					});

					recorderInstance.record().then(() => {
						button.classList.add("ptt-pressed");
						button.innerText = "SAMO CQ";
					});
				});
			} else {
				log.log("You are not transmitting");

				recorderInstance.stop();
				protocol.call("releaseLock");
				button.classList.remove("ptt-pressed");
				button.innerText = "PTT";

				pttPressed = !pttPressed;
			}
		});
	});
};

button.addEventListener("click", connect);
